.btn-primary {
  color: #fff;
  background-color: #137166;
  border-color: #137166;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

.form-group {
  margin-top: 5px;
}

.form_select {
  text-align: center;
  max-width: 130px;
  width: 80%;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  border-radius: 0.35rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.form-select:focus {
  border-color: #3f4d67;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(63, 77, 103, 0.25);
}

.aberto {
  color: #fff;
  background-color: #137166;
}

.fechado {
  color: #fff;
  background-color: red;
}

.surveys {
  color: black;
  background-color: white;
}

td {
  vertical-align: middle;
}

.page-link {
  color: #137166;
}

.table td,
.table th {
  border-top: 1px solid #eaeaea;
  white-space: nowrap;
  padding: 0.8rem 0.75rem;
  padding-top: 0.8rem;
  padding-right: 0.75rem;
  padding-bottom: 0.8rem;
  padding-left: 0.75rem;
}

.page-item.active .page-link {
  color: #fff;
  background-color: #137166;
  border-color: #137166;
}

.table {
  text-align: center;
}

.statusColum {
  min-width: 180px;
}
