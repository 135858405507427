.b-brand {
  text-decoration: none;
}
.navbar-collapsed .b-brand .b-title {
  -webkit-transform: rotateY(-90deg);
  transform: rotateY(-90deg);
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  opacity: 0;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.navbar-collapsed .mobile-menu {
  -webkit-transform: rotateY(-90deg);
  transform: rotateY(-90deg);
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  opacity: 0;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.navbar-collapsed:hover .b-brand .b-title {
  -webkit-transform: rotateY(0deg);
  transform: rotateY(0deg);
  opacity: 1;
}
.navbar-collapsed:hover .mobile-menu {
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
  -webkit-transform: rotateY(0deg);
  transform: rotateY(0deg);
  opacity: 1;
}

.header-logo {
  position: relative;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  float: left;
  height: 70px;
  text-align: center;
  width: 264px;
  margin-right: 0;
  padding: 10px 20px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.navbar-hide {
  width: 64px;
  -webkit-transition: width 1s ease-in-out;
  -moz-transition: width 1s ease-in-out;
  -o-transition: width 1s ease-in-out;
  transition: width 1s ease-in-out;
}

.body-hide {
  margin-left: 100px;
  -webkit-transition: margin-left 1s ease-in-out;
  -moz-transition: margin-left 1s ease-in-out;
  -o-transition: margin-left 1s ease-in-out;
  transition: margin-left 1s ease-in-out;
}

.navbar-footer {
  bottom: 0;
  width: 100%;
  position: absolute !important;
}
