.pcoded-main-container_list {
  position: relative;
  margin: 0 auto;
  margin-left: auto;
  display: block;
  clear: both;
  float: unset;
  right: 0;
  min-height: 100vh;
  transition: all 0.3s ease-in-out;
}
.btn-primary {
  color: #fff;
  background-color: #137166;
  border-color: #137166;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

.form-group {
  margin-top: 10px;
}

.btn-group-sm > .btn,
.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

.form-select-search {
  background: #f4f7fa;
  padding: 10px 20px;
  font-size: 14px;
}
