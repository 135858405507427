.lcontainer {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 38px;
  justify-items: center;
  align-items: center;
  grid-template-rows: 100vh;
  background-color: #f6f6f6;
}

h2 {
  color: #b3b6b9;
}

.spanLink {
  color: #1890ff;
  background-color: transparent;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
}

.footer {
  color: rgb(206, 212, 217);
  text-align: center;
  font-size: 12px;
  padding: 1rem;
  user-select: none;
  margin-top: -45px;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

.login-form {
  width: 300px;
}
.login-form-forgot {
  float: right;
}
.login-form-button {
  width: 100%;
}

.hidden {
  display: none;
}

.lItem {
  width: 300px;
}

.loginForm {
  background-color: white;
  padding-left: 30px;
  padding-top: 20px;
  margin-top: -60px;
  margin-left: -30px;
  margin-right: -30px;
  border-radius: 8px;
  border: 1px solid #d8d8da;
}

.loginImage {
  margin-top: -160px;
  margin-bottom: 70px;
}

tbody {
  font-size: 14px;
}

.footerLink {
  text-decoration: none;
  color: rgb(206, 212, 217);
}

.btn-primary_login {
  color: #fff;
  background-color: #137166;
  border-color: #137166;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  margin-bottom: 20px;
}

.btn-primary:hover {
  background-color: rgb(205, 210, 188);
  border-color: rgb(205, 210, 188);
}

.form-check-input:checked {
  background-color: #137166;
  border-color: #137166;
}

.form-check-input:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(205, 210, 188, 0.25);
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 1.125rem;
}

.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(205, 210, 188, 0.25);
}

.input-group-text {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #b3b6b9;
  text-align: center;
  white-space: nowrap;
}

.btn-check:focus + .btn-primary,
.btn-primary:focus {
  color: #fff;
  background-color: #137166;
  border-color: #0b423c;
  box-shadow: 0 0 0 0.25rem rgba(11, 66, 60, 0.5);
}
